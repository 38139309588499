export default function Footer({ isMobile }) {
    if (isMobile) {
        return (
            <>
                <div style={{textAlign:"center", paddingLeft:"20px", paddingRight:"20px"}}>
                    <hr></hr>
                    <p style={{ fontSize: "12px", color: "grey" }}>© Cashalo 2024 | All rights reserved.</p>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="footer">
                    <p style={{ fontSize: "12px"}}>© Cashalo 2024 | All rights reserved.</p>
                </div>
            </>
        );
    }
}