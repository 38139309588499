import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import './index.css';
import App from './App';
import CONSTANTS from './Constants';
import 'bootstrap/dist/css/bootstrap.min.css';
import ResetPassword from './views/ResetPassword';

const router = createBrowserRouter([
  {
    path: CONSTANTS.COUNTRY.PH.RESETPASSWORD,
    element: <ResetPassword country={CONSTANTS.COUNTRY.PH}/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} >
      
      <App />
      
    </RouterProvider>
  </React.StrictMode>
);

