import { React } from "react";
import cashaloLogo from "../../../assets/cashaloLogo.png";
import { isMobile, isBrowser } from 'react-device-detect';
import heroImage from "../../../assets/heroImage.png";
import Footer from "../Footer";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";

export default function DynamicLayout({ setErrorMessage, errorMessage, child }) {
    return (
        <Container fluid>
            <Row>
                <Col sm={4}>
                    <div className={isBrowser ? "logo-web" : "logo-mobile"}>
                        <img src={cashaloLogo} alt="logo"></img>
                    </div>
                    {errorMessage !== '' &&
                        <Alert
                            className="mt-4"
                            show={errorMessage !== ''}
                            variant="warning"
                            dismissible
                            onClose={() => setErrorMessage('')}
                        >
                            <Alert.Heading>Unexpected error</Alert.Heading>
                            <p>{errorMessage}</p>
                            <Button onClick={() => {
                                setErrorMessage('')
                            }}>
                                Ok
                            </Button>
                        </Alert>
                    }
                    <div className={isBrowser ? "input-web" : "input-mobile"}>
                        {child}
                    </div>
                    {isMobile && <Footer isMobile={true}></Footer>}
                </Col>
                {isBrowser &&
                    <Col sm={8} className="right-side">
                        <div>
                            <img src={heroImage} alt="heroImage"></img>
                        </div>
                        <Footer isMobile={isMobile}></Footer>
                    </Col>
                }
            </Row>
        </Container>
    );
}