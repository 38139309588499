import { Analytics } from 'analytics'
import segmentPlugin from '@analytics/segment'

const analytics = Analytics({
  app: 'Landing Page',
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_ID
    })
  ]
})

function page(name) {
  analytics.page({ name: name })
}

function track(name, data) {
  analytics.track(name, data);
}

function identify(userId, data) {
  analytics.identify(userId, data);
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    page: page,
    track: track,
    identify: identify
}