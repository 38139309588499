
const CONSTANTS = { 
    COUNTRY:{
        PH:{
            'ACTIVATE':'/activate',
            'COMPANYNAME':'Cashalo',
            'TITLE':'Phillipines',
            'PATH':'',
            'PHONE': '/phone',
            'RESETPASSWORD': '/resetpassword',
            'DOWNLOAD': '/download',
            'OTP': '/otp',
            'APIPREFIX':'',
            'URL': process.env.REACT_APP_PH_BASE_URL, 
            'APIKEY': process.env.REACT_APP_PH_API_KEY, 
            'ENDPOINTS':{
                'REGISTER':'/access/register',
                'UPDATE': '/access/updateuser',
                'LOGIN': '/access/login',
                'ACTIVATE': '/User/Activate',
                'ACCEPTTERMS': '/User/AcceptLegalAgreement',
                'RESETPASSWORD': '/Password/Reset',
            }
        }
    }
}

Object.freeze(CONSTANTS);

export default CONSTANTS;