import { useSearchParams } from "react-router-dom";
import { Form, Button, Spinner, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import segmentClient from "../../segment/client";
import { appInsights } from "../../appInsights";
import DynamicLayout from "../../component/layout/DynamicLayout";
import { FaEye, FaEyeSlash } from "react-icons/fa";


export default function ResetPassword(country) {
    const countrySettings = country.country;

    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');


    useEffect(() => {
        const pageName = 'LandingPage_PasswordReset'
        segmentClient.page(pageName);
        appInsights.trackPageView({ name: pageName });
    }, [])

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
    const [showDoneScreen, setDoneScreen] = useState(false);

    let validatePassword = (password) => {
        var pattern = new RegExp("^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$");
        if (!pattern.test(password)) {
            setIsValidPassword(false);
            return;
        }
        setIsValidPassword(true);
    }
    let validateConfirmPassword = (confirmPassword) => {
        if (password !== confirmPassword) {
            setIsValidConfirmPassword(false);
            return;
        }
        setIsValidConfirmPassword(true);
    }




    let activateAccount = () => {
        setIsLoading(true);
        const resetPasswordRequest = {
            verification_token: code,
            new_password: password
        };

        const submitOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': countrySettings.APIKEY
            },
            body: JSON.stringify(resetPasswordRequest)
        };

        try{
            const url = `${countrySettings.URL}${countrySettings.APIPREFIX}${countrySettings.ENDPOINTS.RESETPASSWORD}`;
            const response = fetch(url, submitOptions)
            .then(response => {
                if(response.status === 200){
                    setDoneScreen(true);
                }
                else{
                    setAlertMessage('We have sent you a new link to your email to reset your password. Otherwise contact support hello@cashalo.com.');
                }
            });

        } 
        catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

    const isDisabledButton =  !isValidPassword || !isValidConfirmPassword || password === '' || confirmPassword === '' ;
   

    return ( 
        <> 
         <DynamicLayout
                setErrorMessage={setAlertMessage}
                errorMessage={alertMessage}
                child={showDoneScreen ? 
                <Form >
                    <h3>Password reset successful</h3>
                    <h2>You can now log into the app</h2>
                </Form> : 
                <Form >
                    <h3>Reset your password</h3>
                    <br></br>
                    <Form.Group id="formPassword">
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                value={password}
                                onChange={({ target: { value } }) => {
                                    validatePassword(value);
                                    setPassword(value);
                                }}
                            />
                            <InputGroup.Text
                                onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </InputGroup.Text>
                        </InputGroup>
                        {!isValidPassword && (
                            <Form.Text className="text-danger">
                                Password must have at least 6 characters, contain a letter, number, and special character
                            </Form.Text>
                        )}
                    </Form.Group>
                    <br></br>
                    <Form.Group id="formConfirmPassword">
                        <InputGroup>
                            <Form.Control
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={({ target: { value } }) => {
                                    validateConfirmPassword(value);
                                    setConfirmPassword(value);
                                }}
                            />
                            <InputGroup.Text
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                            </InputGroup.Text>
                        </InputGroup>
                        {!isValidConfirmPassword && (
                            <Form.Text className="text-danger">
                                Passwords don't match
                            </Form.Text>
                        )}
                    </Form.Group>
                    <br></br>
                    
                    <div>
                        <Button className={isDisabledButton ? "btn btn-secondary mt-3 rounded-pill" : "btn btn-primary mt-3 rounded-pill"} onClick={activateAccount} disabled={isLoading || isDisabledButton}>
                            Reset
                            <Spinner
                                className="mx-2"
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                hidden={!isLoading}>
                            </Spinner>
                        </Button>
                    </div>

                </Form>}
            />

        
        </>
    );
}